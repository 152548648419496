/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ActionReducerMap } from '@ngrx/store';

import { coreAppReducer } from '@ws1c/intelligence-core';
import { IntegrationsState } from '@ws1c/intelligence-onboarding/models/integrations-state.interface';
import { onboardingSurveyReducer } from './onboarding-survey/onboarding-survey.reducer';
import { tenantUrlConfigurationReducer } from './tenant-url-configuration/tenant-url-configuration.reducer';

export const integrationsReducerMap: ActionReducerMap<IntegrationsState> = {
  ...coreAppReducer,
  tenantUrlConfiguration: tenantUrlConfigurationReducer,
  onboardingSurveyState: onboardingSurveyReducer,
};
