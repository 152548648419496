/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler, requestErrorHandlerWithStatus } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import {
  TenantNameAvailabilityStatus,
  TenantUrlRenameRequest,
  TenantUrlUpdateStatus,
  TenantUsersDetail,
} from '@ws1c/intelligence-onboarding/models/tenant-url-configuration';

/**
 * Tenant Url Configuration Service
 *
 * @export
 * @class TenantUrlConfigurationService
 */
@Injectable()
export class TenantUrlConfigurationService {
  /**
   * Initializes instance of TenantUrlConfigurationService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof TenantUrlConfigurationService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Method to retrieve new tenant url availability for a given tenant
   * @param {string} tenantId - Tenant Id
   * @param {string} newTenantName - New tenant name which needs to be checked for availability
   * @returns {Observable<TenantNameAvailabilityStatus>} - Returns observable of TenantNameAvailabilityStatus
   * @memberof TenantUrlConfigurationService
   */
  public getTenantUrlAvailability(tenantId: string, newTenantName: string): Observable<TenantNameAvailabilityStatus> {
    return this.httpService.get(Endpoint.TENANT_AVAILABILITY_ID(tenantId, newTenantName)).pipe(
      map((response: GenericObject) => deserialize(TenantNameAvailabilityStatus, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to retrieve tenant rename status for a given tenant
   * @param {string} tenantId - Tenant Id
   * @returns {Observable<TenantUrlUpdateStatus>} - Returns observable of TenantUrlUpdateStatus
   * @memberof TenantUrlConfigurationService
   */
  public getTenantUrlUpdateStatus(tenantId: string): Observable<TenantUrlUpdateStatus> {
    return this.httpService.get(Endpoint.REPROVISION_STATUS_ID(tenantId)).pipe(
      map((response: GenericObject) => deserialize(TenantUrlUpdateStatus, response.data)),
      catchError(requestErrorHandlerWithStatus),
    );
  }

  /**
   * Method to retrieve tenant users details for a given tenant
   * @param {string} tenantId - Tenant Id
   * @returns {Observable<TenantUsersDetail>} - Returns observable of TenantUsersDetail
   * @memberof TenantUrlConfigurationService
   */
  public getTenantUsersDetail(tenantId: string): Observable<TenantUsersDetail> {
    return this.httpService.get(Endpoint.IDM_USERS_COUNT(tenantId)).pipe(
      map((response: GenericObject) => deserialize(TenantUsersDetail, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to start tenant url rename for a given tenant
   * @param {string} tenantId - Tenant Id
   * @param {TenantUrlRenameRequest} tenantUrlRenameRequest - Holds object containing details of old and new tenant url
   * @returns {Observable<boolean>} - Returns observable of boolean
   * @memberof TenantUrlConfigurationService
   */
  public startTenantUrlRename(tenantId: string, tenantUrlRenameRequest: TenantUrlRenameRequest): Observable<boolean> {
    return this.httpService.post(Endpoint.REPROVISION_ID(tenantId), tenantUrlRenameRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}
