/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { IntegrationDetails } from '@ws1c/intelligence-models';
import {
  TenantNameAvailabilityStatus,
  TenantUrlUpdateStatus,
  TenantUsersDetail,
} from '@ws1c/intelligence-onboarding/models/tenant-url-configuration';

/**
 * Tenant Url Configuration Actions
 *
 * @export
 * @class TenantUrlConfigurationActions
 */
export class TenantUrlConfigurationActions {
  public static readonly CATEGORY = '[TENANT_URL_CONFIGURATION]';

  public static getTenantUrlAvailability = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_URL_AVAILABILITY`,
    props<{ newTenantName: string }>(),
  );

  public static getTenantUrlAvailabilitySuccess = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_URL_AVAILABILITY_SUCCESS`,
    props<{ tenantNameAvailabilityStatus: TenantNameAvailabilityStatus }>(),
  );

  public static getTenantUrlAvailabilityFailure = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_URL_AVAILABILITY_FAILURE`,
  );

  public static resetTenantUrlAvailability = createAction(`${TenantUrlConfigurationActions.CATEGORY} RESET_TENANT_URL_AVAILABILITY`);

  public static getTenantUrlUpdateStatus = createAction(`${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_URL_UPDATE_STATUS`);

  public static getTenantUrlUpdateStatusSuccess = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_URL_UPDATE_STATUS_SUCCESS`,
    props<{ tenantUrlUpdateStatus: TenantUrlUpdateStatus }>(),
  );

  public static getTenantUrlUpdateStatusFailure = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_URL_UPDATE_STATUS_FAILURE`,
  );

  public static getTenantUsersDetail = createAction(`${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_USERS_DETAIL`);

  public static getTenantUsersDetailSuccess = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_USERS_DETAIL_SUCCESS`,
    props<{ tenantUsersDetail: TenantUsersDetail }>(),
  );

  public static getTenantUsersDetailFailure = createAction(`${TenantUrlConfigurationActions.CATEGORY} GET_TENANT_USERS_DETAIL_FAILURE`);

  public static setActiveTenant = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_ACTIVE_TENANT`,
    props<{ activeTenant: IntegrationDetails }>(),
  );

  public static setOnboardingMode = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_ONBOARDING_MODE`,
    props<{ isOnboardingMode: boolean }>(),
  );

  public static setTenantReprovisionCancelModalOpenState = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_TENANT_REPROVISION_CANCEL_MODAL_OPEN_STATE`,
    props<{ isOpen: boolean }>(),
  );

  public static setTenantReprovisionResultModalOpenState = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_TENANT_REPROVISION_RESULT_MODAL_OPEN_STATE`,
    props<{ isOpen: boolean }>(),
  );

  public static setTenantUrlUpdateModalOpenState = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_TENANT_URL_UPDATE_MODAL_OPEN_STATE`,
    props<{ isTenantUrlUpdateModalOpen: boolean }>(),
  );

  public static setTenantUrlUpdateNotificationState = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_TENANT_URL_UPDATE_NOTIFICATION_STATE`,
  );

  public static setTenantUrlUpdateStatusRefreshState = createAction(
    `${TenantUrlConfigurationActions.CATEGORY} SET_TENANT_URL_UPDATE_STATUS_REFRESH_STATE`,
    props<{ isRefreshingTenantUrlUpdateStatus: boolean }>(),
  );

  public static startTenantUrlRename = createAction(`${TenantUrlConfigurationActions.CATEGORY} START_TENANT_URL_RENAME`);

  public static startTenantUrlRenameSuccess = createAction(`${TenantUrlConfigurationActions.CATEGORY} START_TENANT_URL_RENAME_SUCCESS`);

  public static startTenantUrlRenameFailure = createAction(`${TenantUrlConfigurationActions.CATEGORY} START_TENANT_URL_RENAME_FAILURE`);
}
