/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import {
  AlertBannerActions,
  IntegratedServicesActions,
  OrgActions,
  OrgSelectors,
  UserPreferenceActions,
  UserPreferenceAssetsSelectors,
} from '@ws1c/intelligence-core';
import { ALERT_BANNER_TYPE, AlertBannerTarget, LOAD_STATE, OrgPreference, ROUTE_NAMES } from '@ws1c/intelligence-models';
import {
  IntegrationsState,
  TenantNameAvailabilityStatus,
  TenantReprovisioningFlow,
  TenantUrlRenameRequest,
  TenantUrlUpdateStatus,
  TenantUsersDetail,
} from '@ws1c/intelligence-onboarding/models';
import { TenantUrlConfigurationService } from '@ws1c/intelligence-onboarding/services/tenant-url-configuration.service';
import { TenantUrlConfigurationActions } from './tenant-url-configuration.actions';
import { TenantUrlConfigurationSelectors } from './tenant-url-configuration.selectors';

/**
 * TenantUrlConfigurationEffects
 *
 * @export
 * @class TenantUrlConfigurationEffects
 */
@Injectable()
export class TenantUrlConfigurationEffects {
  public readonly NOT_FOUND_STATUS_CODE: number = 404;

  /**
   * getTenantUrlAvailability$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public getTenantUrlAvailability$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.getTenantUrlAvailability),
      withLatestFrom(
        this.store.select(TenantUrlConfigurationSelectors.activeTenantIdSelector),
        this.store.select(TenantUrlConfigurationSelectors.isOnboardingMode),
      ),
      switchMap(
        ([action, activeTenantId, isOnboardingMode]: [
          ReturnType<typeof TenantUrlConfigurationActions.getTenantUrlAvailability>,
          string,
          boolean,
        ]) => {
          const newTenantName: string = action.newTenantName;
          return this.tenantUrlConfigurationService.getTenantUrlAvailability(activeTenantId, newTenantName).pipe(
            map((tenantNameAvailabilityStatus: TenantNameAvailabilityStatus) => {
              return TenantUrlConfigurationActions.getTenantUrlAvailabilitySuccess({ tenantNameAvailabilityStatus });
            }),
            catchError((webError: WebError) => {
              return [
                TenantUrlConfigurationActions.getTenantUrlAvailabilityFailure(),
                AlertBannerActions.showAlertBanner({
                  message: this.i18nService.translate('TENANT_URL_CONFIGURATION.TENANT_URL_AVAILABILITY_FAILURE_MSG', {
                    reason: webError.getFullReason(),
                  }),
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: isOnboardingMode ? AlertBannerTarget.PAGE : AlertBannerTarget.MODAL,
                }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * getTenantUrlUpdateStatus$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public getTenantUrlUpdateStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.getTenantUrlUpdateStatus),
      withLatestFrom(this.store.select(TenantUrlConfigurationSelectors.activeTenantIdSelector)),
      switchMap(([_action, activeTenantId]: [Action, string]) => {
        return this.tenantUrlConfigurationService.getTenantUrlUpdateStatus(activeTenantId).pipe(
          mergeMap((tenantUrlUpdateStatus: TenantUrlUpdateStatus) => [
            TenantUrlConfigurationActions.getTenantUrlUpdateStatusSuccess({ tenantUrlUpdateStatus }),
            TenantUrlConfigurationActions.setTenantUrlUpdateNotificationState(),
          ]),
          catchError(({ status, error }: { status: number; error: WebError }) => {
            const actions: Action[] = [
              TenantUrlConfigurationActions.getTenantUrlUpdateStatusFailure(),
              TenantUrlConfigurationActions.setTenantUrlUpdateNotificationState(),
            ];
            if (status === this.NOT_FOUND_STATUS_CODE) {
              return actions;
            }
            return [
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_STATUS_FAILURE_MSG', {
                  reason: error.getFullReason(),
                }),
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.PAGE,
              }),
              ...actions,
            ];
          }),
        );
      }),
    ),
  );

  /**
   * getTenantUsersDetail$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public getTenantUsersDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.getTenantUsersDetail),
      withLatestFrom(this.store.select(TenantUrlConfigurationSelectors.activeTenantIdSelector)),
      switchMap(([_action, activeTenantId]: [Action, string]) => {
        return this.tenantUrlConfigurationService.getTenantUsersDetail(activeTenantId).pipe(
          map((tenantUsersDetail: TenantUsersDetail) => {
            return TenantUrlConfigurationActions.getTenantUsersDetailSuccess({ tenantUsersDetail });
          }),
          catchError(() => [TenantUrlConfigurationActions.getTenantUsersDetailFailure()]),
        );
      }),
    ),
  );

  /**
   * setActiveTenant$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public setActiveTenant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.setActiveTenant),
      withLatestFrom(this.store.select(OrgSelectors.isOnboardingComplete)),
      filter(
        ([_action, isOnboardingComplete]: [ReturnType<typeof TenantUrlConfigurationActions.setActiveTenant>, boolean]) =>
          isOnboardingComplete,
      ),
      map(() => {
        return TenantUrlConfigurationActions.getTenantUsersDetail();
      }),
    ),
  );

  /**
   * setTenantUrlUpdateNotificationState$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public setTenantUrlUpdateNotificationState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.setTenantUrlUpdateNotificationState),
      withLatestFrom(
        this.store.select(TenantUrlConfigurationSelectors.tenantUrlUpdateStateSelector),
        this.store.select(TenantUrlConfigurationSelectors.isOnboardingMode),
        this.store.select(UserPreferenceAssetsSelectors.customerConnectSupportUrl),
      ),
      switchMap(([_action, tenantUrlUpdateState, isOnboardingMode, customerConnectSupportUrl]: [Action, LOAD_STATE, boolean, string]) => {
        let actionLink: string;
        let actionText: string;
        let alertType: string = ALERT_BANNER_TYPE.WARNING;
        let messageKey: string = 'TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_WARNING_MSG';
        let successActions: Action[] = [];
        switch (tenantUrlUpdateState) {
          case LOAD_STATE.IN_FLIGHT:
            actionText = this.i18nService.translate('COMMON_ACTIONS.REFRESH');
            alertType = ALERT_BANNER_TYPE.INFO;
            messageKey = 'TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_IN_PROGRESS_MSG';
            break;
          case LOAD_STATE.SUCCESS:
            alertType = ALERT_BANNER_TYPE.SUCCESS;
            messageKey = 'TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_SUCCESS_MSG';
            // When tenant rename is successful refresh UI cache when flow was initiated
            // from onboarding page otherwise open tenant rename success modal
            successActions = isOnboardingMode
              ? [
                  UserPreferenceActions.refreshUserPreference({
                    redirectRoute: '',
                  }),
                  IntegratedServicesActions.getIntegratedServices(),
                ]
              : [TenantUrlConfigurationActions.setTenantReprovisionResultModalOpenState({ isOpen: true })];
            break;
          case LOAD_STATE.FAILURE:
            actionLink = customerConnectSupportUrl;
            actionText = this.i18nService.translate('COMMON_ACTIONS.CONTACT_SUPPORT');
            alertType = ALERT_BANNER_TYPE.DANGER;
            messageKey = this.i18nService.translate(
              isOnboardingMode
                ? 'TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_ONBOARDING_PAGE_FAILURE_MSG'
                : 'TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_INTEGRATION_PAGE_FAILURE_MSG',
              { route: `/#/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}` },
            );
            break;
        }
        return [
          ...successActions,
          AlertBannerActions.showAlertBanner({
            actionLink,
            actionText,
            alertType,
            autoDismiss: false,
            message: this.i18nService.translate(messageKey),
            target: AlertBannerTarget.SECTION,
          }),
          TenantUrlConfigurationActions.setTenantUrlUpdateStatusRefreshState({ isRefreshingTenantUrlUpdateStatus: false }),
        ];
      }),
    ),
  );

  /**
   * setTenantUrlUpdateStatusRefreshState$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public setTenantUrlUpdateStatusRefreshState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.setTenantUrlUpdateStatusRefreshState),
      filter((action: ReturnType<typeof TenantUrlConfigurationActions.setTenantUrlUpdateStatusRefreshState>) => {
        return action.isRefreshingTenantUrlUpdateStatus;
      }),
      map(() => {
        return TenantUrlConfigurationActions.getTenantUrlUpdateStatus();
      }),
    ),
  );

  /**
   * startTenantUrlRename$
   * @type {Observable<Action>}
   * @memberof TenantUrlConfigurationEffects
   */
  public startTenantUrlRename$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantUrlConfigurationActions.startTenantUrlRename),
      withLatestFrom(
        this.store.select(TenantUrlConfigurationSelectors.activeTenantIdSelector),
        this.store.select(TenantUrlConfigurationSelectors.activeTenantUrlSelector),
        this.store.select(TenantUrlConfigurationSelectors.newTenantNameSelector),
        this.store.select(TenantUrlConfigurationSelectors.isOnboardingMode),
      ),
      switchMap(
        ([_action, activeTenantId, activeTenantUrl, newTenantName, isOnboardingMode]: [Action, string, string, string, boolean]) => {
          const tenantUrlRenameRequest: TenantUrlRenameRequest = new TenantUrlRenameRequest({
            flowName: isOnboardingMode ? TenantReprovisioningFlow.ONBOARDING : TenantReprovisioningFlow.INTEGRATION_CARD,
            newTenantName,
            oldTenantUrl: activeTenantUrl,
          });
          return this.tenantUrlConfigurationService.startTenantUrlRename(activeTenantId, tenantUrlRenameRequest).pipe(
            mergeMap(() => {
              const actions: Action[] = [
                TenantUrlConfigurationActions.startTenantUrlRenameSuccess(),
                TenantUrlConfigurationActions.getTenantUrlUpdateStatus(),
              ];
              return [
                isOnboardingMode
                  ? OrgActions.updateOrgSettings({
                      orgPreferences: {
                        [OrgPreference.ACCESS_TENANT_REPROVISION_STEP_COMPLETED]: true,
                      },
                    })
                  : TenantUrlConfigurationActions.setTenantUrlUpdateModalOpenState({ isTenantUrlUpdateModalOpen: false }),
                ...actions,
              ];
            }),
            catchError((error: WebError) => [
              TenantUrlConfigurationActions.startTenantUrlRenameFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('TENANT_URL_CONFIGURATION.TENANT_URL_UPDATE_START_FAILURE_MSG', {
                  reason: error.getFullReason(),
                }),
                target: isOnboardingMode ? AlertBannerTarget.PAGE : AlertBannerTarget.MODAL,
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * Initializes instance of TenantUrlConfigurationEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {RouterExtensions} routerExtensions - Router Extensions injected instance
   * @param {Store<IntegrationsState>} store - Integrations store observable instance
   * @param {TenantUrlConfigurationService} tenantUrlConfigurationService - TenantUrlConfigurationService instance
   * @param {I18NService} i18nService
   * @memberof TenantUrlConfigurationEffects
   */
  constructor(
    private actions$: Actions,
    public routerExtensions: RouterExtensions,
    private store: Store<IntegrationsState>,
    private tenantUrlConfigurationService: TenantUrlConfigurationService,
    public i18nService: I18NService,
  ) {}
}
