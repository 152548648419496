/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { LOAD_STATE } from '@ws1c/intelligence-models';
import {
  TenantNameAvailabilityStatus,
  TenantUrlConfigurationState,
  TenantUrlUpdateStatus,
  TenantUsersDetail,
} from '@ws1c/intelligence-onboarding/models/tenant-url-configuration';
import { TenantUrlConfigurationActions } from './tenant-url-configuration.actions';
import { initialTenantUrlConfigurationState } from './tenant-url-configuration.state';

/**
 * _reducer
 * @type {ActionReducer<TenantUrlConfigurationState, Action>}
 */
const _reducer = createReducer(
  initialTenantUrlConfigurationState,

  on(
    TenantUrlConfigurationActions.getTenantUrlAvailability,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantNameAvailabilityStatus: new TenantNameAvailabilityStatus(),
      tenantNameAvailabilityLoadState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUrlAvailabilitySuccess,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.getTenantUrlAvailabilitySuccess>,
    ): TenantUrlConfigurationState => ({
      ...state,
      tenantNameAvailabilityStatus: action.tenantNameAvailabilityStatus,
      tenantNameAvailabilityLoadState: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUrlAvailabilityFailure,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantNameAvailabilityLoadState: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    TenantUrlConfigurationActions.resetTenantUrlAvailability,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantNameAvailabilityStatus: new TenantNameAvailabilityStatus(),
      tenantNameAvailabilityLoadState: LOAD_STATE.NONE,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUrlUpdateStatus,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantUrlStatusLoadState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUrlUpdateStatusSuccess,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.getTenantUrlUpdateStatusSuccess>,
    ): TenantUrlConfigurationState => ({
      ...state,
      tenantUrlStatusLoadState: LOAD_STATE.SUCCESS,
      tenantUrlUpdateStatus: action.tenantUrlUpdateStatus,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUrlUpdateStatusFailure,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantUrlStatusLoadState: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUsersDetail,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantUrlStatusLoadState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUsersDetailSuccess,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.getTenantUsersDetailSuccess>,
    ): TenantUrlConfigurationState => ({
      ...state,
      tenantUsersDetail: action.tenantUsersDetail,
      tenantUrlStatusLoadState: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    TenantUrlConfigurationActions.getTenantUsersDetailFailure,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantUsersDetail: new TenantUsersDetail(),
      tenantUrlStatusLoadState: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    TenantUrlConfigurationActions.setActiveTenant,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.setActiveTenant>,
    ): TenantUrlConfigurationState => ({
      ...state,
      activeTenant: action.activeTenant,
      tenantUrlStatusLoadState: LOAD_STATE.NONE,
      tenantUrlUpdateStatus: new TenantUrlUpdateStatus(),
    }),
  ),

  on(
    TenantUrlConfigurationActions.setOnboardingMode,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.setOnboardingMode>,
    ): TenantUrlConfigurationState => ({
      ...state,
      isOnboardingMode: action.isOnboardingMode,
    }),
  ),

  on(
    TenantUrlConfigurationActions.setTenantReprovisionCancelModalOpenState,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.setTenantReprovisionCancelModalOpenState>,
    ): TenantUrlConfigurationState => ({
      ...state,
      isTenantReprovisionCancelModalOpen: action.isOpen,
    }),
  ),

  on(
    TenantUrlConfigurationActions.setTenantReprovisionResultModalOpenState,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.setTenantReprovisionResultModalOpenState>,
    ): TenantUrlConfigurationState => ({
      ...state,
      isTenantReprovisionResultModalOpen: action.isOpen,
    }),
  ),

  on(
    TenantUrlConfigurationActions.setTenantUrlUpdateModalOpenState,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.setTenantUrlUpdateModalOpenState>,
    ): TenantUrlConfigurationState => ({
      ...state,
      isTenantUrlUpdateModalOpen: action.isTenantUrlUpdateModalOpen,
    }),
  ),

  on(
    TenantUrlConfigurationActions.setTenantUrlUpdateStatusRefreshState,
    (
      state: TenantUrlConfigurationState,
      action: ReturnType<typeof TenantUrlConfigurationActions.setTenantUrlUpdateStatusRefreshState>,
    ): TenantUrlConfigurationState => ({
      ...state,
      isRefreshingTenantUrlUpdateStatus: action.isRefreshingTenantUrlUpdateStatus,
    }),
  ),

  on(
    TenantUrlConfigurationActions.startTenantUrlRename,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantRenameStartState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    TenantUrlConfigurationActions.startTenantUrlRenameSuccess,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantNameAvailabilityLoadState: LOAD_STATE.NONE,
      tenantRenameStartState: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    TenantUrlConfigurationActions.startTenantUrlRenameFailure,
    (state: TenantUrlConfigurationState): TenantUrlConfigurationState => ({
      ...state,
      tenantRenameStartState: LOAD_STATE.FAILURE,
    }),
  ),
);

/**
 * Creates and returns TenantUrlConfigurationState
 * @export
 * @param {TenantUrlConfigurationState} state - Holds state object of type TenantUrlConfigurationState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {TenantUrlConfigurationState}
 */
export function tenantUrlConfigurationReducer(state: TenantUrlConfigurationState, action: Action): TenantUrlConfigurationState {
  return _reducer(state, action);
}
