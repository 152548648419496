/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { LOAD_STATE } from '@ws1c/intelligence-models';
import { TenantReprovisioningStatus } from '@ws1c/intelligence-onboarding/models/tenant-url-configuration/tenant-url-configuration.enum';

/**
 * TenantReprovisionConfig
 *
 * @export
 * @class TenantReprovisionConfig
 */
export class TenantReprovisionConfig {
  public static readonly MINIMUM_TENANT_USERS_COUNT: number = 10;
  // regex to validate tenant name does not start with numerals or dash
  // can only contain alphabets, numerals or dash
  // has minlength of 1, maxlength of 63 and can only end with alphabet or numeral
  public static readonly TENANT_NAME_PATTERN: RegExp = new RegExp('^(?!(-|[0-9]))[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]$');
  public static readonly TENANT_NAME_MAX_CHARS_LIMIT = 63;
  public static readonly TENANT_UPDATE_STATUS_TO_LOAD_STATE_MAP: Record<TenantReprovisioningStatus, LOAD_STATE> = {
    [TenantReprovisioningStatus.IN_PROGRESS]: LOAD_STATE.IN_FLIGHT,
    [TenantReprovisioningStatus.SUCCEEDED]: LOAD_STATE.SUCCESS,
    [TenantReprovisioningStatus.FAILED]: LOAD_STATE.FAILURE,
    [TenantReprovisioningStatus.QUEUED]: LOAD_STATE.IN_FLIGHT,
  };
}
