/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { LOAD_STATE } from '@ws1c/intelligence-models';
import {
  TenantNameAvailabilityStatus,
  TenantUrlConfigurationState,
  TenantUrlUpdateStatus,
} from '@ws1c/intelligence-onboarding/models/tenant-url-configuration';

/**
 * Defines initial state for Tenant Url Configuration
 *
 * @export
 * @type {TenantUrlConfigurationState}
 */
export const initialTenantUrlConfigurationState: TenantUrlConfigurationState = {
  activeTenant: null,
  isOnboardingMode: false,
  isRefreshingTenantUrlUpdateStatus: false,
  isTenantReprovisionCancelModalOpen: false,
  isTenantReprovisionResultModalOpen: false,
  isTenantUrlUpdateModalOpen: false,
  tenantNameAvailabilityLoadState: LOAD_STATE.NONE,
  tenantNameAvailabilityStatus: {} as TenantNameAvailabilityStatus,
  tenantRenameStartState: LOAD_STATE.NONE,
  tenantUrlStatusLoadState: LOAD_STATE.NONE,
  tenantUrlUpdateStatus: {} as TenantUrlUpdateStatus,
  tenantUsersDetail: null,
};
